.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bg-border {
  border: 1px solid rgb(0, 0, 0);
}
.error {
  border: 2px solid #ff6565;
}

.react-calendar-timeline .rct-item.item-weekend {
  background: #21f32c;
  border-color: #29b31a;
}

.btn {
  font-size: 0.9em;
}

.scroll-list {
  font-size: 1.2em;
}
.scroll-list .date-column {
  font-size: 1.3em;
}

/* Custom CSS file or <style> tag */
.custom-checkbox .form-check-input {
  width: 50px;  /* Custom width */
  height: 25px; /* Custom height */
}
.custom-checkbox .form-check-label {
  font-size: 1.2rem; /* Custom font size */
}